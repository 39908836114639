






import { Component, Vue } from 'vue-property-decorator'

import StudyDisplay from '@/partials/components/Events/StudyDisplay.vue'
@Component({
  components: {
    StudyDisplay
  }
})
export default class EventStudyShow extends Vue {

}
